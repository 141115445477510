<template>
  <header class="header">
    <div class="row head">
      <div class="col-sm-auto">
        <router-link :to="{name:'Page_260_'+language}">
          <div class="logo" :class="this.$route.path==='/' ? 'claas' : 'unitech'"></div>
        </router-link>
        <button class="hided-toggler navbar-toggler ms-auto collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarList" aria-controls="navbarList" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="col-sm-auto btn-holder">
<!--        <a class="btn btn-primary login" href="https://edu-v2.ecosim.hu/#login" target="_blank" rel="noopener">{{content.header.menuItem8}}</a>-->
        <a href="https://myaccount.ecosim.hu/versenyek/unitech_2024" target="_blank" rel="noreferrer" class="btn btn-primary register">{{content.header.registration}}</a>
        <select class="form-select hhh" @change="changeLanguage" v-model="language">
          <option value="hu">Hu</option>
          <option value="en">En</option>
        </select>
      </div>
    </div>
    <nav class="navbar navbar-expand-md">
      <button class="navbar-toggler ms-auto collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarList" aria-controls="navbarList" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbarList" style="">
        <ul class="navbar-nav ml-auto">
          <li class="navbar-item">
            <router-link :to="{name:'Page_269_'+language}" class="nav-link">{{ content.header.menuItem1 }}</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{name:'Page_267_'+language}" class="nav-link">{{ content.header.menuItem2 }}</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{name:'Page_268_'+language}" class="nav-link">{{ content.header.menuItem3 }}</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{name:'Page_286_'+language}" class="nav-link">{{ content.header.menuItem9 }}</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{name:'Page_262_'+language}" class="nav-link">{{ content.header.menuItem4 }}</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{name:'Page_263_'+language}" class="nav-link">{{ content.header.menuItem5 }}</router-link>
          </li>
          <li class="navbar-item">
            <router-link :to="{name:'Page_264_'+language}" class="nav-link">{{ content.header.menuItem6 }}</router-link>
          </li>
<!--          <li class="navbar-item">-->
<!--            <router-link :to="{name:'Page_282_'+language}" class="nav-link">{{ content.header.menuItem7 }}</router-link>-->
<!--          </li>-->
        </ul>
        <div class="col-sm-auto control">
          <a class="btn btn-primary login" href="https://edu-v2.ecosim.hu/#login" target="_blank" rel="noopener">{{content.header.menuItem8}}</a>
          <a href="https://myaccount.ecosim.hu/versenyek/unitech_2024" target="_blank" rel="noreferrer" class="btn btn-primary register">{{content.header.registration}}</a>
          <select class="form-select hhh" @change="changeLanguage" v-model="language">
            <option value="hu">Hu</option>
            <option value="en">En</option>
          </select>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import {Response} from '@/classes/response.js'

export default{
  name:'Header',
  props:['initData','contents'],
  data(){
    return{
      language : this.initData.init.language
    }
  },
  computed:{
    content(){
      return this.contents[260]
    }
  },
  methods:{
    changeLanguage(){
      this.initData.init.language = this.language

      let formData = new FormData()
      formData.append('language', this.language)

      this.initData.loader.status = true;
      this.$axios.post('/api/v1/user/language',formData)
          .then((response) => {
            let responseData =  new Response().handle(response)
            this.initData.alerts.push({msg: responseData.msg, type: responseData.msgType})
            if(responseData.status===true) {
              this.initData.init.language = this.language
              let routeName = this.$route.name
              let newRouteName = routeName.substring(0,routeName.length-2)+this.language
              let newRouteData = this.initData.init.routes.filter(function (el) {return el.name===newRouteName})[0]
              this.$router.push({name: newRouteData.name})
            }

          })
          .catch(error => {
            console.log(error.message)
            console.error('There was an error!', error)
          })
          .then(() => {
            this.initData.loader.status = false;
          });
    }
  }

}
</script>

<style scoped>
.head {
  max-width: 1280px;
  margin: 0 auto;
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-holder {
  display: flex;
  justify-content: right;
}

.login {
  margin-right: 1rem;
}

.register {
  margin-right: 2rem;
}

.form-select {
  width: 5rem;
  border-radius: 0;
}

.navbar {
  max-width: 1280px;
  margin: 0 auto;
}

.navbar-item {
  text-decoration: none;
  padding-right: 1.5rem;
}

.nav-link {
  color: var(--black);
  font-style: normal;
  font-weight: 300;
  line-height: 1.5rem;
}

.nav-link:hover, .nav-link:focus {
  color: var(--class-green);
  font-weight: 300;
}

header:deep(.logo.claas){
  width: 226px;
  height: 38px;
  background: url('@/assets/img/class_logo.png') center center no-repeat;
}
header:deep(.logo.unitech){
  width: 185px;
  height: 38px;
  background: url('@/assets/img/uniTech_header_logo.png') center center no-repeat;
}

.control {
  display: none;
}

.hided-toggler {
  display: none;
}

.navbar-toggler {
  margin-right: 1rem;
}

.navbar-nav {
  margin-left: .3rem;
}

@media (max-width: 575px) {
  .logo {
    width: 13rem;
    margin-bottom: 1.5rem;
  }
  .hided-toggler {
    display: flex;
  }
}

@media (max-width: 767px) {
  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .btn-holder {
    display: none;
  }
  .header {
    height: 100%;
    text-align: center;
  }
  .navbar-toggler {
    margin-top: -3rem;
  }
}
</style>