import { createRouter, createWebHistory } from 'vue-router'

const routeData = serverData.init.routes
const routes = []
console.log(routeData)
for(let i=0;i<routeData.length;i++){
  routes.push(
      {
        path: routeData[i]['path'],
        name: routeData[i]['name'],
        component: () => import('../views/'+routeData[i]['component']+'.vue'),
        props: routeData[i]['props']
      }
  )
}


const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
      let dropdown = document.querySelector("#navbarList")
      if(dropdown){
          dropdown.classList.remove("show")
      }
      if (savedPosition) {
          return savedPosition
      } else {
          return { top: 0 }
      }
  },
  history: createWebHistory('/'),
  routes
})

export default router
